<template>
  <node-view-wrapper class="select_auto_texto">
    <input id="autotexto_content" @keydown="keyAction" tabindex="0" v-model="text" />
    <ul class="opcoes show">
      <li @click="onClick(idx)" v-for="(o, idx) in opcoesFiltradas" :key="o.id" :class="{ 'selected': currentLI == idx }">
        {{ o.atalho }} 
      </li>
    </ul>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  data() {
    return {
      // selecionado: this.node.attrs.selecionado || "selecione uma opção",
      currentLI: 0,
      text: this.node.attrs.value == 0 ? "" : this.node.attrs.value,
      opcoes: [],
      opcoesFiltradas: []
    }
  },
  components: {
    NodeViewWrapper
  },
  watch: {
    async text(val) {
      // this.opcoesFiltradas = this.opcoes.filter(o => o.toLowerCase().startsWith(val.toLowerCase()));
      await this.getAutoTextos(val);
      this.updateAttributes({ value: val });
    }
  },
  methods: {
    keyAction(event) {
      // event.preventDefault();
      switch (event.keyCode) {
        case 8:
          // event.preventDefault();
          if (this.text == "") {
            this.setValue()
          }
          break;
        case 27:
        case 32:
          if (this.text == "") {
            event.preventDefault();
            this.setValue("@ ")
          }
          break
        case 38:
          event.preventDefault();
          this.currentLI = this.currentLI > 0 ? --this.currentLI : 0;
          break;
        case 40:
          event.preventDefault();
          this.currentLI = this.currentLI < this.opcoesFiltradas.length - 1 ? ++this.currentLI : this.opcoesFiltradas.length - 1;
          break;
        case 13:
          event.preventDefault();          
          var content = this.opcoesFiltradas[this.currentLI].conteudo;
          this.setValue(content);
          break;
      }
      // console.log("keyAction", event);
      // console.log("???", event.keyCode, event.key, event.code);
    },
    setValue(v) {
      console.log("setValue", v);
      const { editor, getPos, node } = this
      const from = getPos() - 1
      const to = from + node.nodeSize + 1
      editor.commands.deleteNode()
      editor.commands.deleteRange({ from, to })
      if (v)
        editor.commands.insertContent(`<p>${v}</p>`);
      editor.commands.focus();
    },
    onClick(idx) {      
      var content = this.opcoesFiltradas[idx].conteudo;
      this.setValue(content);      
    },
    async getAutoTextos(val) {
      const filtro = val ? `?atalho=${val}` : "";
      const self = this;      
      await self.$http
        .get(`/autoTexto${filtro}`)
        .then((response) => {
          self.opcoes = response.data.content;//.filter(f => f.situacao === true && f.tipo === this.type);
          console.log("self.opcoes", self.opcoes);
          self.opcoesFiltradas = self.opcoes;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar autotextos",
          });
        });
    },
  },
  created() {
    this.getAutoTextos();    
  },
  mounted() {
        
    setTimeout(() => {
      window.document.getElementById('autotexto_content').focus();
    }, 200);

  },
  props: nodeViewProps
};
</script>

<style lang="scss">
.select_auto_texto {
  position: absolute;
  word-wrap: break-word;
  display: inline;
}

#autotexto_content {
  outline: none;
}
.select_auto_texto .opcoes {
  min-width: 800px;
}
</style>
