import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import Component from "./AutoTextoComponent.vue";

export default Node.create({
  name: "auto-texto",

  group: "inline",

  inline: true,
  atom: true,
  selectable: false,  

  parseHTML() {
    return [
      {
        tag: "auto-texto",
      },
    ];
  },

  addAttributes() {
    return {
      value: {
        default: "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {            
    return [
      "auto-texto",
      mergeAttributes(HTMLAttributes)
    ];    
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },

  addCommands() {
    return {
      createAutoTextoComponent:
        (options) =>
        ({ commands }) => {
          options;
          return commands.insertContent({
            type: this.name});
        },
    };
  },
});
